import React from 'react';
import Button from './Button';
import styles from '../pages/css/profile.module.css'
import { Link } from 'react-router-dom';

export default function Level({level}) {
    console.log(level)

    if(level < 0) {
        return (<div style={{marginLeft: '30px'}}>
            У тебя премиум-аккаунт. Ты можешь заходить на любые сервера. Поздравляем!
            <img alt="Школьник-бизнесмен в ванне." src="https://cs8.pikabu.ru/images/big_size_comm/2016-09_2/1473250389154937673.jpg" 
                style={{ width: "400px", height: "300px", marginTop: '10px'}}>
            </img>
        </div>);
    } else {
        return (
            <div className={styles.purchase_zone}>
                <div className="level">Ваш уровень qLauncher: {level + 1}</div>
                <Link tabindex="-1"to={`/level`} ><Button id="purchase" text="ПОВЫСИТЬ" /></Link>
            </div>
        );
    }
}

import { React, useState } from 'react'
import axios from 'axios';
import Cookies from 'universal-cookie';
import styles from './css/login.module.css';
import { useHistory } from 'react-router-dom';

function Login() {
    const [username, setUsername] = useState('username');
    const [password, setPassword] = useState('password');
    const history = useHistory();

    return (
        <div className={styles.loginWrapper}>
            <form className={styles.loginForm} onSubmit={evt => handleSubmit(evt)}>
                <div className={styles.gridLayout}>
                    <label className={styles.label}>Никнейм: </label>
                    <input className={styles.field} value={username} onChange={evt => updateUsernameValue(evt)} type="text" id="username" name="username"></input><br></br>
                    <label className={styles.labelp} htmlFor="password">Пароль: </label>
                    <input className={styles.fieldp} value={password} onChange={evt => updatePasswordValue(evt)} type="password" id="password" name="password"></input><br></br>
                    <input className={styles.submit} type="submit" value="Вход или регистрация"></input>
                </div>
            </form>
        </div>
    );

    function updateUsernameValue(evt) {
        const username = evt.target.value;
        
        setUsername(username);
    }

    function updatePasswordValue(evt) {
        const password = evt.target.value;
        
        setPassword(password);
    }

    function setLoginCookies(username, password) {
        const cookies = new Cookies();
        cookies.set('username', username, { maxAge: 2592000 });
        // domain: 'alauncher.ru', maxAge: 2592000, sameSite: 'strict'
        cookies.set('password', password, { maxAge: 2592000 });
    }

    async function handleSubmit(evt) {
        evt.preventDefault();

        const exists = await axios.get(`https://alauncher.ru:5001/account_exists?username=${username}`)
        if(exists.data.exists === undefined) {
            if(exists.data.error = 'malformed request.') {
                alert("Неверный запрос. Повторите попытку или свяжитесь с администратором сайта.");
            } else {
                alert("Произошка неизвестная ошибка. Повторите попытку или свяжитесь с администратором сайта.");
            }

            return;
        }
        if(exists.data.exists === false) {
            axios.post("https://alauncher.ru:5001/add_account_user", { username: username, 
                password: password})
            .then((response) => {
                if(response.data["success"] === true) {
                    console.log(`success, ${username}`);
                    setLoginCookies(username, password);

                    alert("Аккаунт был успешно зарегистрирован!");

                    history.push(`/profile?username=${username}`);
                }
                else if(response.data["error"] === "malformed request.") {
                    alert("Неверный запрос. Повторите попытку или свяжитесь с администратором сайта.");
                } else if(response.data["error"] === "account already exists") {
                    alert("Аккаунт с таким никнеймом уже зарегистрирован. Выберите другой никнейм.");
                } else {
                    alert("Произошка неизвестная ошибка. Повторите попытку или свяжитесь с администратором сайта.");
                }
            })
            .catch((error) => {
                alert(error);
            })
        } else {
            const login = await axios.post("https://alauncher.ru:5001/login_user", { username: username, 
                password: password})

            console.log(login);
            if(login.data.success === true) {
                setLoginCookies(username, password);

                if(login.data.changed === true) {
                    alert("Пароль на аккаунт был изменён на тот, что вы указали :)");
                }

                history.push(`/profile?username=${username}`);
            } else if(login.data.success === false) {
                alert("Пароль неверен.");
            }
            else {
                if(login.data.error === 'nonexists') {
                    alert("Такого аккаунта не существует. Повторите попытку или свяжитесь с администратором сайта.");
                } else if(login.data.error === 'malformed request.') {
                    alert("Неверный запрос. Повторите попытку или свяжитесь с администратором сайта.");
                } else {
                    alert("Произошка неизвестная ошибка. Повторите попытку или свяжитесь с администратором сайта.");
                }
            }
        }
    }
}

export default Login;

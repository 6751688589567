import styles from '../pages/css/info.module.css'

export default function LevelInfo({level}) {
    return (
        <div className={styles.wrapper}>
            <h1>Уровни aLauncher</h1>
            <article className={styles.article}>
                <p>Уровни aLauncher позволяют...</p>
            </article>
        </div>
    );
}
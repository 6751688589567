import React from 'react'
import Button from '../components/Button'
import NavButton from '../components/NavButton';
import styles from './css/index.module.css';

export default function Index() {
    return (
        <div className={styles.wrapper}>
            <div className={styles.textblock}>
                <h1 className={styles.introduce}>aLauncher</h1>
                <p className={styles.description}>— это лучший лаунчер для Майнкрафт.<br></br>Перестаньте вводить бесящие пароли каждый раз, заходя на сервер.</p>
            </div>

            <div className={styles.buttonblock}>
                <a tabindex="-1" href={require("../downloads/installer.exe") } download="installer.exe"><Button className={styles.download} text="ЗАГРУЗИТЬ ДЛЯ WINDOWS" /></a>
                <NavButton className={styles.login} link="/login" text="ВОЙТИ В АККАУНТ"/>
            </div>

            <div className={styles.infoBlock}>
                <a className={styles.link} href={"https://discord.gg/XdTm4UZZ"}>Наш Дискорд-сервер</a>
                |
                <a className={styles.link} href={"mailto:support@alauncher.ru"}>Написать в поддержку</a>
            </div>
        </div>
    )
}

import React from 'react'
import Button from '../components/Button';
import styles from './css/profile.module.css';
import axios from 'axios';
import Level from '../components/Level';
import ReactSkinview3d from "react-skinview3d"
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';


export default class Profile extends React.Component
{
  state = {
    username: null,
    password: null,
    level: 0,
    skinWindow: null
  }

  constructor(props) {
    super(props);

    this.inputFileRef = React.createRef();
    this.inputCapeRef = React.createRef();
    this.onFileChange = this.handleFileChange.bind( this );
    this.onCapeChange = this.handleCapeChange.bind( this );
    this.onBtnClick = this.handleBtnClick.bind( this );
    this.onCapeClick = this.handleCapeClick.bind( this );
    this.onLogoffClick = this.handleLogoffClick.bind( this );
  }

  async handleFileChange( e ) {
    if (e.target.files) {
      const formData = new FormData();
      formData.append("skin", e.target.files[0]);
      
      axios.post(`https://alauncher.ru:5001/change_skin?username=${this.state.username}&password=${this.state.password}`, formData)
      .then((response) => {
        this.state.skinWindow.loadSkin(`https://alauncher.ru:5001/get_skin?username=${this.state.username}&nocache_timestamp=${(new Date()).getMilliseconds()}`);
      })
    }
  }

  async handleCapeChange( e ) {
    if (e.target.files) {
      const formData = new FormData();
      formData.append("cape", e.target.files[0]);

      axios.post(`https://alauncher.ru:5001/change_cape?username=${this.state.username}&password=${this.state.password}`, formData)
      .then((response) => {
        this.state.skinWindow.loadCape(`https://alauncher.ru:5001/get_cape?username=${this.state.username}&nocache_timestamp=${(new Date()).getMilliseconds()}`);
      })
    }
  }

  handleBtnClick() {
    /*Collecting node-element and performing click*/
    this.inputFileRef.current.click();
  }

  handleCapeClick() {
    /*Collecting node-element and performing click*/
    this.inputCapeRef.current.click();
  }

  handleLogoffClick() {
    console.log("removed cookies");
    const cookies = new Cookies();
    cookies.remove("username");
    cookies.remove("password");
  }

  componentDidMount() {
    const cookies = new Cookies();
    const queryParams = new URLSearchParams(window.location.search);

    const username = queryParams.get("username");
    const password = cookies.get("password");

    this.setState({ username: username });
    this.setState({ password: password });

    axios.get(`https://alauncher.ru:5001/has_cape?username=${username}`).then((response) => {
        if(response.data.exists !== undefined) {
          if(response.data.exists === true) {
            this.state.skinWindow.loadCape(`https://alauncher.ru:5001/get_cape?username=${username}`);
          }
        }
      });  

    axios.get(`https://alauncher.ru:5001/get_level?username=${username}`)
      .then(res => {
        const level = res.data.level;
        this.setState({ level: level });

        if(level === undefined) {
          this.setState({ username: "undefined" });
          this.setState({ level: -1 });
        }
      })
      .catch(reason => {
        return (
          <div style={{"textAlign": "center"}}>Ошибка :(</div>
        )
      });
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.skin_zone}>
          <div className={styles.upper_buttons}>
          <input
            type="file"
            ref={this.inputFileRef}
            onChange={this.onFileChange}
            style={{display: 'none'}}
          />
          <Button id="upload_skin" text="ИЗМЕНИТЬ СКИН" onClick={this.onBtnClick} />
          <input
            type="file"
            ref={this.inputCapeRef}
            onChange={this.onCapeChange}
            style={{display: 'none'}}
          />
          <Button id="upload_cape" text="ИЗМЕНИТЬ ПЛАЩ" onClick={this.onCapeClick} />
          </div>
          <div className={styles.skin_preview}>
            <ReactSkinview3d height={400} width={300} onReady={({
              viewer
            }) => {
              if(this.state.skinWindow === null) {
                this.setState({ skinWindow: viewer });
              }
              
              const queryParams = new URLSearchParams(window.location.search);
              viewer.loadSkin(`https://alauncher.ru:5001/get_skin?username=${queryParams.get("username")}`);
              viewer.loadCape(`https://alauncher.ru:5001/get_cape?username=${queryParams.get("username")}`);
            }} />
          </div>
          <div className={styles.nickname_wrapper}>{this.state.username}</div>
          <Link tabindex="-1"to={`/`} ><Button id="logoff" text="ВЫЙТИ" onClick={this.onLogoffClick} /></Link>
        </div>
        <Level level={this.state.level}/>
      </div>
    )
  }
}

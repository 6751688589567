import React from 'react'
import Button from './Button'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'

export default function NavButton({id, text, link}) {
    const cookies = new Cookies();
    if(cookies.get('username') !== undefined) {
      console.log(cookies.get('username'))
      return (
        <Link tabIndex="-1" to={`/profile?username=${cookies.get('username')}`} ><Button id={`${id}`} class="Button" text={`${text}`}></Button></Link>
      )
    } else {
      return (
        <Link tabIndex="-1" to={'/login'} ><Button id={`${id}`} class="Button" text={`${text}`}></Button></Link>
      )
    }
  }

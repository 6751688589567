import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Profile from "./pages/Profile"
import Index from "./pages/Index"
import Login from "./pages/Login"
import LevelInfo from "./pages/LevelInfo";

export const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route path="/" exact>
                    <Index />
                </Route>
                <Route path="/profile" exact>
                    <Profile />
                </Route>
                <Route path="/login" exact>
                    <Login />
                </Route>
                <Route path="/level" exact>
                    <LevelInfo />
                </Route>
                <Route>
                    <div style={{"text-align": "center"}}>Нет такой страницы :(</div>
                </Route>
            </Switch>
        </Router>
    )
}

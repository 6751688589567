import logo from './logo.svg';
import './App.css';
import { Routes } from './Routes';
import './pages/css/global.css';

function App() {
  return (
    <Routes />
  );
}

export default App;
